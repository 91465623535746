@use 'themes' as *;
.subheader nav {
  position: fixed;
  width: 100%;
  z-index: 999;
  align-items: unset !important;
  box-shadow: 0 0 0 0 rgba(44, 51, 73, 0.1) !important;
  color: unset !important;
  background: nb-theme(subheader-background);
  border-bottom: nb-theme(subheader-border-bottom);
}
