@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/dark';
@use "@nebular/theme/styles/global/breakpoints" as *;
$nb-enable-css-custom-properties: true;
$grid-breakpoints: (
  xs: 0,
  is: 400px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  macpro: 1280px,
  xxl: 1400px,
  xxxl: 1600px
);

// DEFAULT
$nb-themes: nb-register-theme((
  //background-basic-color-3: #fafafa,
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: #f7f9fc,
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
  user-initials-text-font-family:'Roboto',
  user-name-text-font-family:'Roboto',
  user-title-text-font-family:'Roboto',
  font-family-primary:'Roboto',
  tabset-content-padding:1rem 0,
  input-large-text-font-weight:unset,
  select-large-text-font-weight:unset,
  header-title-color:#8f9bb3,
  sidebar-padding:1.25rem 0 1.25rem 0,
  timeline-before-color:rgba(52, 40, 104, .1),
  header-height:3.75rem,
  header-padding:0,
  header-shadow-2:unset,
  subheader-background: #f1f7fd,
  subheader-border-bottom:3px solid #bfddfb,
  chat-shadow:unset,
  spinner-primary-background-color:unset,
  tag-text-transform:unset
), default, default);

// PUTZ
$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: #f7f9fc,
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
  user-initials-text-font-family:'Roboto',
  user-name-text-font-family:'Roboto',
  user-title-text-font-family:'Roboto',
  font-family-primary:'Roboto',
  tabset-content-padding:1rem 0,
  input-large-text-font-weight:unset,
  select-large-text-font-weight:unset,
  header-title-color:#8f9bb3,
  sidebar-padding:1.25rem 0 1.25rem 0,
  timeline-before-color:rgba(52, 40, 104, .1),
  header-height:3.75rem,
  header-padding:0,
  header-shadow-2:unset,
  subheader-background: #f1f7fd,
  subheader-border-bottom:3px solid #bfddfb,
  chat-shadow:unset,
  tag-text-transform:unset
), putz, default);

// DARK
$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
  user-initials-text-font-family:'Roboto',
  user-name-text-font-family:'Roboto',
  user-title-text-font-family:'Roboto',
  font-family-primary:'Roboto',
  tabset-content-padding:1rem 0,
  input-large-text-font-weight:unset,
  select-large-text-font-weight:unset,
  header-title-color:#8f9bb3,
  sidebar-padding:1.25rem 0 1.25rem 0,
  timeline-before-color:rgba(143, 155, 179, 0.48),
  header-height:3.75rem,
  header-padding:0,
  header-shadow-2:unset,
  subheader-background: #222b45,
  subheader-border-bottom:3px solid #c5cee0,
  chat-shadow:unset,
  tag-text-transform:unset
), dark, dark);


/* stylelint-disable max-line-length */
$nb-themes: nb-register-theme((
  font-family-primary: unquote('-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'),
  font-family-secondary: font-family-primary,

  // custom
  content-width: 1140px,
  settings-col-width: 0,
  settings-col-margin: 0,
  color-fg-heading-light: #ffffff,
  header-menu-fg-active: color-fg,
  footer-menu-fg: color-fg-text,
  code-block-bg: linear-gradient(225deg, #333c66 0%, #1d2447 100%),
  color-gray-light: #ced5dd,
  radius: 0.375rem,
  color-fg-highlight: #40dc7e,

  select-min-width: 5rem,

  color-fg: white,
  color-fg-heading: #0d1c2e,
  color-fg-text: #919fb1,
  shadow: none,
  layout-background-color: transparent,
  layout-padding: 0,
  layout-medium-padding: 0,
  layout-small-padding: 0,
  footer-text-color: color-fg-text,
  footer-icon-color: #cdd6e3,
  footer-background-color: transparent,
  footer-divider-width: 0,
  header-padding: 0 0,
  header-height: 4.25rem,
  header-fg: color-fg-heading-light,
  menu-item-padding: 0.675rem 1rem,
  menu-fg: color-fg-heading-light,
  menu-active-fg: menu-fg,
  footer-height: 18.75rem,
  footer-padding: 1.25rem 0,
  menu-font-size: 0.95rem,
  menu-font-weight: font-weight-normal,
  link-text-decoration: none,
), docs-home, default);
/* stylelint-enable max-line-length */

$nb-themes: nb-register-theme((
  // custom
  content-width: 1440px,
  settings-col-width: 19rem,
  settings-col-margin: 0.375rem,
  color-gray-light: #ced5dd,
  color-fg-heading-light: #8994a3,
  code-block-bg: linear-gradient(225deg, #333c66 0%, #1d2447 100%),
  color-info: #5699f0,
  color-warning: #f09301,
  header-menu-fg-active: color-fg-highlight,

  radius: 0.25rem,
  separator: transparent,
  color-bg: transparent,
  color-fg: #494949,
  color-fg-text: #494949,
  color-fg-heading: rgba(0, 0, 0, 0.88),
  shadow: 0 8px 20px 0 rgba(218, 224, 235, 0.6),
  layout-background-color: #fafafa,
  layout-padding: 3.25rem 1.25rem 3.25rem 1rem,
  header-bg: white,
  sidebar-padding: 2rem,
  sidebar-shadow: none,
  color-fg-highlight: color-primary-default,
  link-color:	color-fg-highlight,
  link-color-hover: color-fg-highlight,
  link-color-visited: color-fg-highlight,
  header-padding: 0,
  header-fg: black,
  menu-fg: black,
  menu-submenu-fg: color-fg-heading-light,
  menu-active-fg: menu-fg,
  menu-submenu-item-container-padding: 0 1rem,
  menu-submenu-active-border-color: transparent,
  menu-submenu-active-fg: color-fg-highlight,
  menu-active-font-weight: bold,
  card-bg: white,
  card-header-font-size: 2rem,
  card-header-font-weight: bold,
  card-header-fg-heading: black,
  card-margin: 2.5rem,
  footer-shadow: none,
  footer-text-color: color-fg-heading-light,
), docs-page, docs-home);
