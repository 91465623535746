@import "~jsoneditor/dist/jsoneditor.min.css";
/* You can add global styles to this file, and also import other style files */
.font-size-12px {
  font-size: 12px !important;
}

.spinner-centralized{
  position: absolute;
  width: 200px;
  height: 200px;
  top: 40%;
  left: 50%;
}

.color-white{
  color: white!important;
}