@use "custom-componentes" as *;
// themes - our custom or/and out of the box themes
@use 'themes' as *;
@use "sub-header" as *;
// framework component themes (styles tied to theme variables)
@use '@nebular/theme/styles/globals' as *;

@use './layout' as *;
@use '../../../../components/guided-tour/src/lib/guided-tour-base-theme' as *;
//@import './overrides';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');
// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include ngx-layout();
  @include custom-theme-components();
}

.poppins {
  font-family: 'Poppins', sans-serif !important;
}

html, body {
  width: 100%;
  height: 100%;
}


.header-title {
  @extend .header-title-color;
  font-weight: bold;
}

.header-title-color {
  color: nb-theme(header-title-color) !important;
}
.spinner-content {
  min-height: 100px
}

.spinner-no-bg nb-spinner.status-primary {
  background-color: unset;
}

.max-width-200px{
  max-width: 200px !important;
}
.max-width-300px{
  max-width: 300px !important;
}
